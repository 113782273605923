import { useContext } from 'react';
import { MobileSizeContext } from '../../../../context/MobileSizeContext';
import { UserSettingsContext } from '../../../../context/user-settings-context';

function useCloseDrawerOnMobile(fn) {
  const { isMobileHeight } = useContext(MobileSizeContext);
  const { setSidebarOpened } = useContext(UserSettingsContext);

  return (...args) => {
    fn(...args);

    if (isMobileHeight) {
      setSidebarOpened(false);
    }
  };
}

export default useCloseDrawerOnMobile;
