import PlusInCircleIcon from '@pelckmans/business-components/icons/PlusInCircle';
import { string } from 'prop-types';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../enums/analytics';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { getCurrentDigibook, getCurrentModuleId } from '../../../../../../selectors/digibooks';
import api from '../../../../../../services/api';
import { buildDoceoUrl } from '../../../../../../utils/buildDoceoUrl';
import NotLicensedModal from '../../../dialogs/NotLicensedModal';
import { openNewTabFallback } from '../../../../../../utils/window';
import useCloseDrawerOnMobile from '../hooks/useCloseDrawerOnMobile';

function AddButton({ text, toolTip, nodeId }) {
  const moduleId = useSelector(getCurrentModuleId);
  const digibook = useSelector(getCurrentDigibook);
  const analytics = useAnalytics();
  const { t } = useTranslation();

  const [notLicensedVisible, setNotLicensedVisible] = useState(false);
  const licensed = digibook.teacherDigitalManualLicense;

  const addSlideSetAndCloseDrawer = useCloseDrawerOnMobile(async evt => {
    evt.preventDefault();
    const { data } = await api.post(`/studio/user/modules/${moduleId}/table-of-content/${nodeId}/slide-sets`);

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_CREATED,
      objectId: data.id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });

    return openNewTabFallback(buildDoceoUrl(data, true), 'noopener noreferrer');
  });

  return (
    <li className="pbb-list__item">
      {notLicensedVisible &&
        createPortal(
          <NotLicensedModal title={t('notLicensed.accessFeature.title')} message={t('notLicensed.accessFeature.message')} onClose={() => setNotLicensedVisible(false)} />,
          document.body,
        )}
      <div className="pbb-list__link-wrap">
        {licensed ? (
          <a href="/#" onClick={addSlideSetAndCloseDrawer} className="pbb-list__link" title={toolTip}>
            <PlusInCircleIcon className="pbb-list__link-icon" />
            <span className="pbb-list__link-text">{text}</span>
          </a>
        ) : (
          <button type="button" onClick={() => setNotLicensedVisible(true)} className="pbb-list__link" title={toolTip}>
            <PlusInCircleIcon className="pbb-list__link-icon" />
            <span className="pbb-list__link-text">{text}</span>
          </button>
        )}
      </div>
    </li>
  );
}

AddButton.propTypes = {
  text: string.isRequired,
  toolTip: string.isRequired,
  nodeId: string.isRequired,
};

export default AddButton;
